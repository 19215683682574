import React, { useState } from 'react'

const ValidateForm = (callback) => {

    //Form values
    const [values, setValues] = useState("");
    //Errors
    const [errors, setErrors] = useState(false);

    const validate = (event, name, value) => {

        if(name === 'style') {
                setErrors(value[0] && value[1])
        } else {
            setErrors(value ? true : false)
        }
    }

    //A method to handle form inputs
    const handleChange = (event) => {
        let name = event.name;
        let val = event.val;

        validate(event, name, val);

        setValues({
            ...values,
            [name]: val,
        })

    }


    const handleSubmit = (event) => {
        if (event) event.preventDefault();

        const data = new FormData(event.target);
        let formLength=0;
        let insertedValues = 0;
        for (let [key, value] of data.entries()) {
            if (value){
                insertedValues ++;
            }
            formLength ++;
          }
   
        // if (formLength === 1 || (Object.keys(errors).length === 0 && Object.keys(values).length === formLength)) {
        if (Object.keys(errors).length === 0 && insertedValues === formLength) {
            callback();

        } else {
            setErrors({
                ...errors,
                error: 'error'
            })
        }
    }

    return {
        values,
        errors,
        handleChange,
        handleSubmit
    }
}

export default ValidateForm