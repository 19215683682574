import { Grid } from '@material-ui/core';
import { Modal, Box, IconButton } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import './Dashboard.css';
import { HeaderContext } from "../../components/dashboard";
import { ApiContext } from "../../contexts/ApiContext";
import StoreMasterTable from './StoreMasterTable';

function StoreMaster() {
    const { selectedWED, fileData, selectedClient, selectedRetailer, showEmail, storeMasterData, storeMasterOriginalData, multi, userGroup } = useContext(HeaderContext)
    const { StoreMasterAPI } = useContext(ApiContext);
    const { user } = useAuth0()

    let tempData = storeMasterOriginalData;

    return (
        tempData &&
        <Grid item xl={12} lg={12} md={12} sm={12} style={{ paddingBottom: '0px' }}>
            <StoreMasterTable initialData={tempData} selectedClient={selectedClient} selectedRetailer={selectedRetailer} multi={multi} userGroup={userGroup}/>
        </Grid>

    );
}

export default StoreMaster;