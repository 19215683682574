import React from "react";
import "react-step-progress-bar/styles.css";
import { Grid } from '@material-ui/core';
import ProgressBar from "./progressBar";


function ProgressOutline({progressPageId, retailerDataProp, abortController, setStillLoading, setSumitted, userGroup}) {
    
    return (
        <div className="ProgressbarPage" style={{ paddingBottom:0 }}>
            <Grid container>
                <Grid item lg={12}>
                    <ProgressBar retailerDataProp={retailerDataProp.retailerData} socketProp = {retailerDataProp.wsclient} file={progressPageId} abortController={abortController} setStillLoading={setStillLoading} setSumitted={setSumitted} userGroup={userGroup}/>
                </Grid>
            </Grid>
        </div>
    );
}

export default ProgressOutline;
