import { createContext, useState } from 'react';
import SpinnerRetailer from "../components/Spinner/SpinnerRetailer";

export const OverlayContext = createContext({});

export const OverlayProvider = ({ children }) => {
    const [isStart, setAction] = useState(false);

    const OverlayAction = {
        start: () => {
            !isStart && setAction(true)
        },
        stop: () => {
            isStart && setAction(false)
        }
    }

    return (
        < OverlayContext.Provider value={{ OverlayAction }} >
            {
                <>
                    <div style={{ visibility: isStart ? 'hidden' : 'visible' }}> {children} </div>
                    {isStart &&
                        <SpinnerRetailer />
                    }
                </>
            }
        </OverlayContext.Provider >
    );
};

