/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useMemo,
    useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInt from '../utils/axios'

export const ApiContext = React.createContext();

export const ApiProvider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();

    const navigateTo = (ex) => history.push({ pathname: '/error', state: ex });

    const [history, updateHistory] = useState();
    const getAPI =
        async (config) => {
            let access_token_hook = await getAccessTokenSilently();
            config.headers = {
                authorization: `Bearer ${access_token_hook}`
            };
            return new Promise((resolve, reject) => {
                axiosInt.request(config)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(ex => {
                        reject(ex)
                        //console.log(ex)
                        //resolve(ex)

                    })
            })
        };

    const LogAPI = async (filename, tpi, wed, id, clientName, retailer, user, uploadedFile, client_id, userGroup) => {
        let config = {
            url: '/logRouter',
            params: { filename: filename, tpi: tpi, wed: wed, id: id, clientName: clientName, retailer: retailer, user: user, uploadedFile: uploadedFile, client_id: client_id, userGroup: userGroup },
            method: 'post',
            responseType: 'arraybuffer',
        }
        let response = await getAPI(config);
        return response
    }

    const GetClientAPI = async () => {
        let config = {
            url: '/bazaarConfigRouter',
            method: 'get',
        }
        let response = await getAPI(config);
        return response
    }

    const GetTableDataAPI = async (client, wed, retailer) => {
        let config = {
            url: '/gridDataRouter',
            method: 'get',
            params: { client: client, wed: wed, retailer: retailer },
        }
        let response = await getAPI(config);
        return response
    }

    const TemplateDownloadAPI = async (param) => {
        let config = {
            url: '/fileDownload',
            method: 'get',
            responseType: 'blob',
            params: param,
        }
        let response = await getAPI(config);
        return response
    }

    const PastFileDownloadAPI = async (row, user, userGroup) => {
        let config = {
            url: '/pastFileRouter',
            method: 'get',
            responseType: 'blob',
            params: { clientId: row.client_id, retailer: row.retailer, fileName: row.skypadFile, user: user, userGroup: userGroup },
        }
        let response = await getAPI(config);
        return response
    }

    const StyleMasterDownloadAPI = async (client) => {
        let config = {
            url: '/styleMasterRouter',
            method: 'get',
            params: { client: client },
        }
        let response = await getAPI(config);
        return response
    }

    const sessionCheckAPI = async (user) => {
        let config = {
            url: '/sessionCheckRouter',
            method: 'get',
            params: { user: user },
        }
        let response = await getAPI(config);
        return response
    }

    const SubmitAPI = async (fileName, tpid, retailer, client, weekend, uniqueId, uploadedFileName, userGroup) => {
        let config = {
            url: '/submit',
            method: 'post',
            params: { fileName: fileName, tpid: tpid, retailer: retailer, client: client, weekend: weekend, uniqueId: uniqueId, uploadedFileName: uploadedFileName, userGroup: userGroup },
        }
        let response = await getAPI(config);
        return response
    }

    const ValidationAPI = async (formData, client, retailer, id, user, userGroup, signal) => {
        let config = {
            url: '/files',
            method: 'post',
            data: formData,
            params: { client, retailer, id, user, userGroup },
            signal: signal,
        }
        try {
            let response = await getAPI(config);
            return response;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request aborted');
            }
            throw error;
        }
    }

    const clientLogAPI = async (user, client, retailer, printOut, userGroup) => {
        let config = {
            url: '/logFromClient',
            method: 'get',
            params: { user: user, client: client, retailer: retailer, logs: printOut, userGroup: userGroup },
        }
        let response = await getAPI(config);
        return response
    }

    const sendEmailAPI = async (sender, email, subject, body, client, retailer, user, userGroup) => {
        let config = {
            url: '/sendEmail',
            method: 'post',
            params: { from: sender, to: email, subject: subject, body: body, client: client, retailer: retailer, user: user, userGroup: userGroup },
        }
        let response = await getAPI(config);
        return response
    }

    const StoreMasterAPI = {
        get: async (client_id, retailer, user) => {
            let config = {
                url: "/stores",
                method: "get",
                params: {
                    client_id,
                    retailer,
                    user,
                },
            };
            let response = await getAPI(config);
            return response;
        },
        post: async (data, user, selectedClient, selectedRetailer, userGroup) => {

            let config = {
                url: "/stores",
                method: "post",
                data,
                params: {
                    user,
                    selectedClient,
                    selectedRetailer,
                    userGroup,
                },
            };
            let response = await getAPI(config);
            return response;
        },
    };

    const GetHistoryDataAPI = async (client, wed, retailer, user, userGroup) => {
        let config = {
            url: '/historyData',
            method: 'get',
            params: { client: client, wed: wed, retailer: retailer, user: user },
        }
        let response = await getAPI(config);
        return response
    }

    const contextValue = useMemo(() => {
        return {
            PastFileDownloadAPI,
            TemplateDownloadAPI,
            GetClientAPI,
            GetTableDataAPI,
            LogAPI,
            StyleMasterDownloadAPI,
            sessionCheckAPI,
            SubmitAPI,
            ValidationAPI,
            updateHistory,
            clientLogAPI,
            sendEmailAPI,
            StoreMasterAPI,
            GetHistoryDataAPI
        };
    }, [
        PastFileDownloadAPI,
        TemplateDownloadAPI,
        GetClientAPI,
        GetTableDataAPI,
        LogAPI,
        StyleMasterDownloadAPI,
        sessionCheckAPI,
        SubmitAPI,
        ValidationAPI,
        updateHistory,
        clientLogAPI,
        sendEmailAPI,
        StoreMasterAPI,
        GetHistoryDataAPI
    ]);


    return (
        <ApiContext.Provider value={contextValue}>
            {children}
        </ApiContext.Provider>
    );
};

