
import { Box, Typography } from "@mui/material";
function Dashboard() {
  return (
    <Box sx={{ pt: 2, pb: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        TBD
      </Typography>
    </Box>
  );
}

export default Dashboard;