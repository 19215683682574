/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';

import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from '@mui/material/Tooltip';


export default function ErrorHeader() {
    const { user, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const helpLink = () => {
        let path = 'https://skypad.zendesk.com/hc/en-us';
        window.open(path, '_blank');
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        // sessionCheckAPI(user.nickname)
        //     .then(
        //         (response) => {
        //             logout({ returnTo: window.location.origin });

        //         },
        //         (reject) => {
        //             console.log(reject)
        //         })
        logout({ returnTo: window.location.origin });
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection='column'
                    >
                        <Grid>
                            
                        </Grid>
                        <Grid container style={{ width: 'auto' }} columnSpacing={1} >
                            <Grid>
                                <Tooltip title="Account settings">
                                    <IconButton
                                        sx={{
                                            flexDirection: 'column',
                                            paddingTop: 0,
                                            borderRadius: '6px'
                                        }}
                                        aria-label="account of current user"
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        color="inherit"
                                    >
                                        <AccountCircle sx={{ fontSize: '30px', width: 32, height: 32 }} ></AccountCircle>
                                        <span className="profile-name">{user.name}</span>

                                    </IconButton>

                                </Tooltip>

                            </Grid>
                            <Grid>
                                <Tooltip title="Help">
                                    <IconButton
                                        sx={{
                                            flexDirection: 'column',
                                            paddingTop: 0,
                                            ml: 1,
                                            borderRadius: '6px'
                                        }}
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={helpLink}
                                        color="inherit"
                                    >
                                        <ContactSupportIcon sx={{ fontSize: '30px', width: 32, height: 32 }} ></ContactSupportIcon>
                                        <span className="profile-name">Help</span>

                                    </IconButton>
                                </Tooltip>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        fontWeight: 'bold',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon >
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
}