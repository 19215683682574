import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import HistoryIcon from '@mui/icons-material/History';

const tabs = [
  // {
  //   title: 'Dashboard',
  //   path: '/dashboard',
  //    icon: <AssessmentOutlinedIcon />,
  //    flag:''
  // },
  {
    title: 'Upload Data',
    path: '/upload',
    icon: <UploadFileOutlinedIcon />,
    flag:''
  },
  {
    title: 'Historical Data',
    path: '/history',
    icon: <HistoryIcon />,
    flag:''
  },
  {
    title: 'Store Master',
    path: '/storemaster',
    icon: <StoreOutlinedIcon />,
    flag:''
  },
];

export default tabs;