import PropTypes from 'prop-types';
//import Footer from '../Footer';
import { makeStyles } from "@material-ui/core/styles";
import { Container } from '@material-ui/core';
import ContentWrapper from '../../components/ContentWrapper';
import Error from '../../content/Error';
import ErrorHeader from '../../content/Error/errorHeader';
import ErrorNav from '../../content/Error/errorNav';
import { styled } from '@mui/material/styles';


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 76;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100vh',
  alignContent: 'center',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: 'unset'
  },
  drawerPaper: {
    padding: '0px',
    backgroundColor: theme.palette.grey,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  // appBarSpacer: { marginTop: '55px' },
  content: {
    flexGrow: 1,
    height: "100vh",
    paddingBottom: '30px',
    paddingTop: '54px',
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },

}));

const NoLayout = ({ children }) => {
  const classes = useStyles();
  // return (
  //   <div className={classes.root}>
  //     <main className={classes.content}>
  //       <div className={classes.appBarSpacer} />
  //       <Container maxWidth="xxl" className={classes.container}>
  //         <ContentWrapper title=''>
  //           {children}
  //         </ContentWrapper>
  //       </Container>
  //     </main>
  //   </div>
  // );
  return (
    <StyledRoot>
        <ErrorHeader />
        <ErrorNav />
        <Main>
          <Error/>
        </Main>
    </StyledRoot>
  );
};

NoLayout.propTypes = {
  children: PropTypes.node
};

export default NoLayout;
