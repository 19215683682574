import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import GlobalStyles from './globalStyles';
// import componentsOverride from './overrides';
import bazaartheme from './BazaarTheme';
// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 825,
    lg: 1280,
    xl: 1400,
    xxl: 1725
  },
}
export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      breakpoints,
      palette,
      shape: { borderRadius: 6 },
      typography: {fontFamily: "Segoe UI, Arial, sans-serif"},
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = bazaartheme(theme);
 
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}