import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import SkypadLogo from '../skypad_logo.png'

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '90vh',
        margin: 'auto',
        alignItems: 'center',
    },

}));

export default function CircularIndeterminate() {
    const classes = useStyles();

    return (
        <div className={classes.root} >
             <img src={SkypadLogo} width="150" className='mb-5' alt=""/>
            <CircularProgress style={{color:'#54B948'}} className='mb-3'/>
            <div style={{ display: 'block' }}>Loading your Application List...</div>
        </div>
    );
}