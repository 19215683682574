import { useNavigate, useLocation} from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiContext } from '../../contexts/ApiContext';
import RouterInit from './router';


const Routes = () => {
    let navigation = useNavigate();
    let historyHook = useLocation();
    const { user } = useAuth0();
    const { updateHistory } = useContext(ApiContext)

    useEffect(() => {
        if (historyHook.pathname === '/' && user) {
            // updateHistory(history)
            let t = !user['http://skypad_user/groups'].includes('Sky Internal') && user['http://skypad_user/groups'].filter(x => x.includes('SkyBazaar')).length === 0;
            if (t) {
                navigation({ pathname: '/error', state: "You don't have access to this application" });
            } else {
                navigation('/upload');
            }
        }
    }, [historyHook])

    return (
        <>
                <RouterInit />
        </>
    );
}

export default Routes;
