import React from "react";
import "react-step-progress-bar/styles.css";
import { Grid } from '@material-ui/core';
import EmailForm from "./emailForm";

function EmailPage({retailerDataProp, displayClient, handleCloseEmail, userGroup}) {
    
    return (
        <div className="ProgressbarPage" style={{ paddingBottom:0 }}>
            <Grid container>
                <Grid item lg={12}>
                    <EmailForm retailerDataProp={retailerDataProp} displayClient={displayClient} handleCloseEmail={handleCloseEmail} userGroup={userGroup} />
                </Grid>
            </Grid>
        </div>
    );
}

export default EmailPage;
