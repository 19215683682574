import React, { useCallback } from 'react';
import { IconButton, Tooltip} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

const RevertButton = ({ rowId, editedRowsRef, setData, initialData }) => {
    const handleRevert = useCallback(() => {
        if (editedRowsRef && editedRowsRef.current && rowId in editedRowsRef.current) {
            delete editedRowsRef.current[rowId];

            setData(prevData => prevData.map(row => {
                if (row.id === rowId) {
                    return initialData.find(initialRow => initialRow.id === rowId) || row;
                }
                return row;
            }));

            window.dispatchEvent(new Event('updateSubmitButton'));
        }
    }, [rowId]);

    return (
        <Tooltip style={{ }} title={<>
            Undo changes
        </>} placement='bottom' arrow>
            <IconButton
                onClick={handleRevert}
                size="small"
            >
                <ReplayIcon fontSize="small" />
            </IconButton>
        </Tooltip>

    );
};

export default RevertButton;