import React from 'react';

function ValidateEmail(input) {

    let validRegex = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}$/;

    let trimmedInput = input.trim();

    if (trimmedInput.includes(' ')) {
        return false;
    }

    if (trimmedInput.match(validRegex)) {
        return true;
    } else {
        return false;
    }
}

export default ValidateEmail;