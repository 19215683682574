/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";

import { Box, Grid } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axiosInt from '../../utils/axios';
import { useAuth0 } from '@auth0/auth0-react';
import { HeaderContext } from "../../components/dashboard";
import { ApiContext } from "../../contexts/ApiContext";


function FormSection({ formName, values, setLRetailer, setLBrand, setLSession }) {

    const [allSeasonList, setAllSeasonList] = useState([]);

    const { selectedClient, clientList, selectedRetailer } = useContext(HeaderContext);
    const { StyleMasterDownloadAPI } = useContext(ApiContext);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(async () => {
        if (formName === 'style' && (values.lBrand || selectedClient.key !== "All")) {
            let clientName = values.lBrand || selectedClient.key;
            StyleMasterDownloadAPI(clientName)
                .then(res => {
                    setAllSeasonList(res)
                }).catch(function () {
                })
        }
    }, [values.lBrand]);

    useEffect(async () => {
        if (selectedRetailer && selectedRetailer !== "All"){
            setLRetailer(selectedRetailer)
        }
    }, [selectedRetailer]);

    useEffect(async () => {
        if (selectedClient && selectedClient.val !== "All"){
            setLBrand(selectedClient.key)
        }
    }, [selectedClient]);

   
    // if (selectedRetailer){
    //     setLRetailer(selectedRetailer.val)
    // }

    let uniqueClient = clientList.reduce((unique, o) => {
        if (!unique.some(obj => obj.client_id === o.client_id && obj.client_name === o.client_name)) {
            unique.push(o);
        }
        return unique;
    }, []);

    let uniqueRetailer = clientList.reduce((unique, o) => {
        if (!unique.some(obj => obj.retailer === o.retailer)) {
            unique.push(o);
        }
        return unique;
    }, []);

    return (
        <Box sx={{ boxShadow: 10, borderRadius: 7 }} style={{ paddingBottom: 30, paddingLeft: 30, paddingRight: 30, paddingTop: 0 }}>
            <Grid style={{ justifyContent: 'center' }}>
                {formName === 'style' ?
                    <>
                        <small><i>Please select one option from each filter</i></small><Grid item lg={12}>
                            <FormControl variant="standard" sx={{ m: 1, width: '80%' }}>
                                <InputLabel id="demo-simple-select-standard-label">Brand</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    onChange={e => { setLBrand(e.target.value) }}
                                    label="style"
                                    name="style"
                                    defaultValue={(values.lBrand || selectedClient.key !== "All") ? values.lBrand || selectedClient.key : null}
                                >
                                    {uniqueClient ? uniqueClient.sort((a, b) => (a.client_name > b.client_name) ? 1 : -1).map(value => {
                                        return (<MenuItem value={value.client_id}>{value.client_name}</MenuItem>);
                                    }) : null}

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={12}>
                            <FormControl variant="standard" sx={{ m: 1, width: '80%' }}>
                                <InputLabel id="demo-simple-select-standard-label">Season</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    onChange={e => { setLSession(e.target.value) }}
                                    label="style"
                                    name="style"
                                >
                                    {allSeasonList ? allSeasonList.sort((a, b) => (a.season > b.season) ? 1 : -1).map((s) => {
                                        return (<MenuItem value={s.season}>{s.season}</MenuItem>);
                                    }) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                    : formName === 'store' ?
                        <FormControl variant="standard" sx={{ m: 1, width: '80%' }}>
                            <InputLabel shrink={values.lRetailer? true: false} id="demo-simple-select-standard-label">Select One</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                onChange={e => { setLRetailer(e.target.value) }}
                                label="store"
                                name="store"
                                value={values.lRetailer && values.lRetailer !== "All" ? values.lRetailer : null}
                            >
                                {clientList && selectedClient.key !== "All" ? clientList.filter((c => c.client_id === selectedClient.key)).sort((a, b) => (a.retailer > b.retailer) ? 1 : -1).map((rl) => {
                                    return (<MenuItem value={rl['retailer']}>{rl['retailer']}</MenuItem>)
                                }) : clientList ?  uniqueRetailer.sort((a, b) => (a.retailer > b.retailer) ? 1 : -1).map((rl) => {
                                    return (<MenuItem value={rl['retailer']}>{rl['retailer']}</MenuItem>) }) : null}
                            </Select>
                        </FormControl>

                        : formName === 'retail' ?
                            <FormControl variant="standard" sx={{ m: 1, width: '80%' }}>
                                <InputLabel shrink={values.lRetailer? true: false} id="demo-simple-select-standard-label">Select One</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    onChange={e => { setLRetailer(e.target.value) }}
                                    label="retail"
                                    name="retail"
                                    value={values.lRetailer && values.lRetailer !== "All" ? values.lRetailer : null}
                                >
                                    {clientList && selectedClient.key !== "All" ? clientList.filter((c => c.client_id === selectedClient.key)).sort((a, b) => (a.retailer > b.retailer) ? 1 : -1).map((rl) => {
                                    return (<MenuItem value={rl['retailer']}>{rl['retailer']}</MenuItem>)
                                }) : clientList ?  uniqueRetailer.sort((a, b) => (a.retailer > b.retailer) ? 1 : -1).map((rl) => {
                                    return (<MenuItem value={rl['retailer']}>{rl['retailer']}</MenuItem>) }) : null}
                                </Select>
                            </FormControl> :
                            ''}
            </Grid>
        </Box >
    );
}

export default FormSection;
