import React, { useState, useContext, useCallback, useEffect, useRef, useMemo } from 'react';
import { ApiContext } from '../../contexts/ApiContext';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Box, Typography, CircularProgress, TextField, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const CustomHeader = React.memo(({ column, filterValue, onFilterChange, sortDirection, onSortClick, isSortActive }) => {
    const handleFilterInputChange = (e) => {
        const newValue = e.target.value;
        onFilterChange(column.field, newValue);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50%', cursor: 'pointer' }} onClick={onSortClick}>
                {isSortActive ? <Typography fontSize={12} color={'black'} fontWeight="bold">
                    {column.headerName}
                </Typography> :
                <Typography fontSize={12} fontWeight="bold">
                {column.headerName}
            </Typography>}
                
                {isSortActive && (
                    <Box component="span" sx={{ ml: 0.5, display: 'flex', alignItems: 'center' }}>
                        {sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ fontSize: 14 }} /> : <ArrowDownwardIcon sx={{ fontSize: 14 }} />}
                    </Box>
                )}
            </Box>
            <Box sx={{ height: '50%', display: 'flex', alignItems: 'center' }}>
                <TextField
                    size="small"
                    fullWidth
                    value={filterValue || ''}
                    onChange={handleFilterInputChange}
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '28px',
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                borderRadius: 1,
                            },
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: '4px 8px',
                            fontSize: '13px',
                        },
                    }}
                />
            </Box>
        </Box>
    );
});

export default CustomHeader;
