import { Modal } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import TemplatePage from '../../../content/download/templatePage';
import { HeaderContext } from '..';
// ----------------------------------------------------------------------

NavSection.propTypes = {
    data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
    const { showEmail } = useContext(HeaderContext)
    if(data && !showEmail) {
        data = data.filter(item => item.title !== 'Store Master')
    }
    return (
        <Box {...other}>
            <List disablePadding>
                {data.map((item) => (
                    <NavItem key={item.title} item={item} />
                ))}
            </List>
        </Box>
    );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
};

function NavItem({ item }) {
    const { title, path, icon, info, flag } = item;
    const [popup, setPopUp] = React.useState(false);
    const templateArr = [{ type: 'retail', value: ['POS', 'POS Upload Template.xlsx'] },
    { type: 'style', value: ['', 'Style Master.csv'] },
    { type: 'store', value: ['StoreMaster', 'StoreMaster.xlsx'] }]

    const handleClose = () => {
        setPopUp(false)
    }

    const showPopup = () => {
        setPopUp(true)
    }

    const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
        ...theme.typography.body2,
        position: 'relative',
        textTransform: 'capitalize',
        marginBottom: '10px',
        border: '2px solid rgba(0, 0, 0, 0.23)',
        padding: '6px 0',
        borderRadius: '5px',
        fontWeight: 'bold', justifyContent: 'start', textTransform: 'none',
        '&:hover': {
            background: '#dde1ff',
            color: '#5569FF',
            border: '2px solid #5569FF'
        },

    }));

    const StyledNavItemIcon = styled(ListItemIcon)({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'inherit'

    });

    const ButtonStyle = styled(Button)({
        width: '100%',
        color: 'inherit',
        fontWeight: 'bold',
        justifyContent: 'start',
        textTransform: 'none',
        marginBottom: '10px',
        border: '2px solid rgba(0, 0, 0, 0.23)',
        padding: '6px 0',
        borderRadius: '5px',
        fontWeight: 'bold', justifyContent: 'start', textTransform: 'none',
        '&:hover': {
            background: '#dde1ff',
            color: '#5569FF',
            border: '2px solid #5569FF'
        },
    });

    return (
        flag === 'download' ?
            <>
                <ButtonStyle
                    onClick={() => showPopup(path)}
                >
                    <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> {title}
                    {info && info}
                </ButtonStyle>

                <Modal
                    open={popup}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{}}
                >
                    <Box style={{
                        width: 390, position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        outline: 'none',
                        backgroundColor: '#e4ebf5',
                        borderRadius: 5
                    }}>
                        <Box sx={{
                            display: 'flex',
                            padding: 0.5,
                            paddingRight: 2,
                            flexDirection: 'row-reverse'
                        }}>
                            <Avatar onClick={() => { handleClose(); }} sx={
                                {
                                    '&:hover': {
                                        backgroundColor: '#EEEEEE',
                                    }, color: '#BFBFBF', bgcolor: '#FFFFFF', position: 'absolute', right: '-20px', top: '-20px', border: '2px solid'
                                }}>
                                <CloseIcon />
                            </Avatar>
                        </Box>
                        <TemplatePage tabName={path} />
                    </Box>
                </Modal></>
            :
            <StyledNavItem
                component={RouterLink}
                to={path}
                sx={{
                    '&.active': {
                        color: '#5569FF',
                        bgcolor: '#dde1ff',
                    },
                }}
            >
                <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> {title}

                {info && info}
            </StyledNavItem>

    );
}