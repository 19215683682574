import { makeStyles } from '@material-ui/core/styles';
import {
    Button, Typography
} from '@material-ui/core';
import { OverlayContext } from '../../contexts/OverlayContext';
import { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '90vh',
        margin: 'auto',
        alignItems: 'center',
    },
}));

function Error(props) {
    const classNames = useStyles();
    let { OverlayAction } = useContext(OverlayContext);

    OverlayAction.stop();

    return (

        <div
            style={{
                
                fontSize: "25px",
                justifyContent: "center",
                display: "flex",
            }}
        >
            Access Denied. Please reach out to&nbsp;
            <a href="mailto:asquad@skyitgroup.com">asquad@skyitgroup.com</a>
            &nbsp;for further troubleshooting.
        </div>
    )
}

export default Error;
