import "react-dropzone-uploader/dist/styles.css";
import { useContext, useState } from 'react'
import Dropzone from "react-dropzone-uploader";
import { Box, Grid } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { useAuth0 } from '@auth0/auth0-react';
import $ from 'jquery';
import ListItem from '@mui/material/ListItem';
import Logo from '../../components/skypad_logo.png';
import { ApiContext } from "../../contexts/ApiContext";



function DropZone({ setProgressPageId, retailerDataProp, userGroup }) {
    const { user } = useAuth0();
    const [validationMessage, setValidationMessage] = useState(false);
    const pickedRetailer = retailerDataProp.fileName ? retailerDataProp.fileName : retailerDataProp.retailer.replaceAll(" ", "")
    const pickedWed = retailerDataProp.weekend.replaceAll("-", "")
    const { clientLogAPI } = useContext(ApiContext);

    const handleValidation = (event) => {
        const retailerName = event.file.name.split(' ')[0];
        let wed = event.file.name.split(' ')[1];
        const fileExtension = event.file.name.split('.').pop().toLowerCase();
        let msg = null;

        if (fileExtension !== 'xlsx') {
            $(".dzu-submitButtonContainer").hide();
            setValidationMessage('This file cannot be submitted because the file type needs to be .xlsx');
            msg = `${event.file.name} cannot be submitted because the file type is not .xlsx`;
        }

        else if (retailerName !== pickedRetailer || wed.split('.')[0] !== pickedWed) {
            $(".dzu-submitButtonContainer").hide();
            setValidationMessage(`This file cannot be submitted because the name should include ${pickedRetailer} ${pickedWed}`);
            msg = `${event.file.name} cannot be submitted because the file has wrong name`;
        } 

        else {
            setValidationMessage('');
        }

        try {
            if (msg) {
                clientLogAPI(user.nickname, retailerDataProp.client_id, retailerDataProp.retailer, msg, userGroup)
                    .then((res) => {
                        console.log("sent to log")
                    });
            }
        } catch (error) {
            console.log(error)
        }

        return false;
    }

    const handleSubmit = async (event) => {
        setProgressPageId(event[0].file);
    }

    const Preview = ({ meta, fileWithMeta }) => {
        const { name, size } = meta;
        return (
            <Box sx={{ textAlign: 'center', minHeight: '280px', paddingTop: '20px' }}>
                <span style={{ marginTop: 10, fontFamily: 'Helvetica', fontSize: '15px' }}>
                    {name}, {Math.round(size / 1024)} KB
                </span>

                {!validationMessage ?
                    <Box sx={{ mt: 2 }}>
                        <button onClick={fileWithMeta.remove} style={{ display: 'block', margin: '10px auto', background: 'none', border: 'none', cursor: 'pointer', fontSize: '25px' }}>
                            &times;
                        </button>
                        <Typography className="validation-message" style={{ marginTop: '150px', }}>
                            Click "Submit" to continue
                        </Typography>
                    </Box>
                    :
                    <Box sx={{ mt: 12 }}>
                        <Typography className="validation-message" style={{ color: 'red', marginTop: '70px' }}>
                            {validationMessage}
                        </Typography>
                        <button onClick={fileWithMeta.remove} style={{ margin: '50px auto', background: 'none', border: 'none', cursor: 'pointer', fontSize: '25px' }}>
                            &times;
                        </button>
                    </Box>
                }
            </Box>
        )
    }


    return (
        <Box sx={{ boxShadow: 10 }} style={{ paddingBottom: 30, paddingLeft: 30, paddingRight: 30, paddingTop: 0 }}>
            <Grid container style={{ padding: '25px 15px 0px' }}>
                <Grid item lg={5} sx={{ width: '100%' }}>
                    <Dropzone
                        onSubmit={handleSubmit}
                        validate={handleValidation}
                        onChangeStatus={({ meta }, status) => {
                            console.log(status, meta)
                        }}
                        PreviewComponent={Preview}
                        inputContent={
                            <Grid container direction='column' justifyContent="center"
                                alignItems="center">
                                <Box display="flex"
                                    justifyContent="center"
                                    alignItems="center" sx={{ width: 100, height: 100, borderRadius: 10 }} style={{ backgroundColor: '#ECECEC' }}>
                                    <FontAwesomeIcon
                                        style={{
                                            color: "#949494",
                                            fontSize: 50,
                                        }}
                                        icon={faCloudUploadAlt}
                                    />
                                </Box>
                                <Typography style={{ fontWeight: 'bold', color: 'black', paddingTop: 15 }}>Drop a file here or click to upload </Typography>
                                <Typography style={{ fontWeight: 'bold', color: 'black', paddingTop: 15 }}>Max file size: 10MB </Typography>
                            </Grid>}


                        styles={{ dropzone: { minHeight: 350, overflow: "hidden", borderStyle: "dashed", backgroundColor: '#fff' }, dropzoneReject: { color: 'red' } }}
                        maxSizeBytes={1024 * 1024 * 10}
                        maxFiles={1}
                        multiple={false}
                        accept="*"
                    />

                </Grid>
                <Grid item lg={7} style={{ paddingLeft: '40px' }}>
                    <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', justifyContent: 'center' }}>
                        <img src={Logo} style={{ width: '60%', height: '60%' }} />
                    </Box>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>Retailer:</Typography>
                        <Typography style={{ paddingLeft: '10px' }}> {retailerDataProp.retailer} </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>Brand:</Typography>
                        <Typography style={{ paddingLeft: '10px' }}> {retailerDataProp.client_name}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>Week-Ending Date: </Typography>
                        <Typography style={{ paddingLeft: '10px' }}> {retailerDataProp.weekend} </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography style={{ fontWeight: 'bold' }}>File Name: </Typography>
                        <Typography style={{ paddingLeft: '10px' }}> {retailerDataProp.fileName} {retailerDataProp.weekend.replaceAll("-", "")}</Typography>
                    </ListItem>
                </Grid>
            </Grid>

        </Box >
    );
}

export default DropZone;
