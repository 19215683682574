import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, Typography } from '@mui/material';
import Logo from '../../../components/skypad_logo.png';
import { useTheme } from '@mui/material/styles';
const NAV_WIDTH = 240;

// ----------------------------------------------------------------------

ErrorNav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};
  
export default function ErrorNav() {
    // const { pathname } = useLocation();
    // useEffect(() => {
    //     if (openNav) {
    //         onCloseNav();
    //     }
    // }, [pathname]);
    const theme = useTheme();
   
    const renderContent = (
        <>
            <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', justifyContent: 'center' }}>
                <img src={Logo} style={{ width: '100%', height: '100%' }} />
            </Box>
        </>
    );

    return (
        <Box
            
            sx={{
                flexShrink: { md: 0 },
                width: { md: NAV_WIDTH, lg: 275, xl: 300 },
            }}
        >
            {(
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: { md: NAV_WIDTH,lg: 275, xl: 300 },
                            bgcolor: 'background.paper',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}