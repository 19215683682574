import axios from 'axios';

let url = window.location.origin;
if (window.location.hostname === 'localhost') {
  url = "http://localhost:3839/"
}

export const BASE_URL = url;

const axiosInt = axios.create({
  baseURL: BASE_URL // + '3839/'
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

export default axiosInt;