import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import StoreIcon from '@mui/icons-material/Store';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';

const download = [
  {
    title: ' POS Template',
    path: 'retail',
    icon: <ShoppingCartOutlinedIcon />,
    flag: 'download'
  },
  {
    title: 'Style Master',
    path: 'style',
    icon: <CheckroomIcon />,
    flag: 'download'
  },
  // {
  //   title: 'Store Master',
  //   path: 'store',
  //   icon: <StoreOutlinedIcon />,
  //   flag: 'download'
  // },

];

export default download;