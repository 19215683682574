
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

import Header1 from './header';
import PropTypes from 'prop-types';
// ----------------------------------------------------------------------
const NAV_WIDTH = 240;
const NAV_WIDTHLG = 275;
const NAV_WIDTHXL = 300;
const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 65;

function HideOnScroll(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const StyledRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: 'white',
    color: "#000000",

    // [theme.breakpoints.up('md')]: {
    //     width: `calc(100% - ${NAV_WIDTHXL + 1}px) !important`,
    // },
    [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${NAV_WIDTH}px)`,
    },
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTHLG}px)`,
    },
    [theme.breakpoints.up('xl')]: {
        width: `calc(100% - ${NAV_WIDTHXL}px)`,
    },

}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    justifyContent: 'end',
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 0),
    },
    [theme.breakpoints.up('md')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 2),
    },
}));
Header.propTypes = {
    onOpenNav: PropTypes.func,
};

export default function Header() {
    return (

        <StyledRoot>
            <StyledToolbar>
                <Header1 />
            </StyledToolbar>
        </StyledRoot>

    );
}