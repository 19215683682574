import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, Typography } from '@mui/material';
import Logo from '../../skypad_logo.png';
import NavSection from './navSection';
import tabs from './tabs';
import download from './downloadButtons';
import { useTheme } from '@mui/material/styles';
const NAV_WIDTH = 240;

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};


  
export default function Nav() {
    // const { pathname } = useLocation();
    // useEffect(() => {
    //     if (openNav) {
    //         onCloseNav();
    //     }
    // }, [pathname]);
    const theme = useTheme();
   
    const renderContent = (
        <>
            <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', justifyContent: 'center' }}>
                <img src={Logo} style={{ width: '100%', height: '100%' }} />
            </Box>
            <Box sx={{ px: 2.5, py: 3 }}>
                <Typography color='#808080' >
                    TABS
                </Typography>
                <NavSection sx={{ mt: 1 }} data={tabs} />
                <Typography color='#808080' sx={{ mt: '40px !important' }}>
                    DOWNLOAD FILES
                </Typography>
                <NavSection sx={{ mt: 1 }} data={download} />
            </Box>

            <Box sx={{ flexGrow: 1 }} />
        </>
    );

    return (
        <Box
            component="nav"
            
            sx={{
                flexShrink: { md: 0 },
                width: { md: NAV_WIDTH, lg: 275, xl: 300 },
            }}
        >
            {(
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: { md: NAV_WIDTH,lg: 275, xl: 300 },
                            bgcolor: 'background.paper',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}