
// import { createTheme } from "@mui/material/styles";

import typography from "./typography";

const themeColors = {
    primary: '#5569FF',
    secondary: '#333f99'
};

// const theme = createTheme({
//     palette: {
//         primary: {
//             main: themeColors.primary,
//         },
//         secondary: {
//             main: themeColors.secondary,
//         },
//     },
//     typography: {
//         fontFamily: [
//             'Roboto',
//             '-apple-system',
//             'BlinkMacSystemFont',
//             '"Segoe UI"',
//             '"Helvetica Neue"',
//             'Arial',
//             'sans-serif',
//             '"Apple Color Emoji"',
//             '"Segoe UI Emoji"',
//             '"Segoe UI Symbol"',
//         ].join(','),
//         '.MuiTypography-h6': {
//             fontFamily: 'serif',
//         },
//     },

//     components: {

// });

export default function bazaarTheme(theme) {
    return {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    fontWeight:'bold',
                    color: '#212B36',
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    fontWeight:'bold',
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    '& svg:hover': {
                        color: themeColors.secondary
                    },
                },
            },
        }
    }
};
