import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';

const DynamicButton = React.memo(({ isDisabledCallback, ...props }) => {
  const [, forceUpdate] = useState({});

  const updateButton = useCallback(() => forceUpdate({}), []);

  useEffect(() => {
    window.addEventListener('updateSubmitButton', updateButton);
    return () => {
      window.removeEventListener('updateSubmitButton', updateButton);
    };
  }, [updateButton]);

  return (
    <Button
      {...props}
      disabled={isDisabledCallback()}
    />
  );
});

export default DynamicButton;