import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect } from 'react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';


import { OverlayContext } from './contexts/OverlayContext';
import Routes from './layout/Route';

function App() {

  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading
  } = useAuth0();

  const { OverlayAction } = useContext(OverlayContext);

  useEffect(() => {
    OverlayAction.start();
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        // OverlayAction.stop();
      } else if (!isAuthenticated) {
        loginWithRedirect();
      }
    }
  }, [isAuthenticated, isLoading])

  return (isAuthenticated && !isLoading && <BrowserRouter><Routes /></BrowserRouter>)
}

export default App;
