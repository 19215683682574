/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { Tooltip, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { CardHeader, Box, Divider, Stack, Chip, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import InfoIcon from '@mui/icons-material/Info';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInt from '../../utils/axios';
import { ApiContext } from '../../contexts/ApiContext';


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>

            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>

        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const StickyTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(246, 247, 248)',
        color: theme.palette.common.white,
        left: 0,
        position: "sticky",
        zIndex: 1,
        //zIndex: theme.zIndex.appBar + 2,
        padding: 0,
        width: '15%'
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: "white",
        left: 0,
        position: "sticky",
        zIndex: 1,
        //zIndex: theme.zIndex.appBar + 1,
        padding: 0

    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        fontWeight: 'bold',
        color: '#808080',
        fontSize: '13px',
        '& svg': {
            marginRight: '-15px'
        }
    },
    [`&.${tableCellClasses.body}`]: {
        //fontSize: 14,
        // fontWeight: 'bold',
    },
}));

function descendingComparator(a, b, orderBy) {

    let aVal = a[orderBy] !== null ? a[orderBy] : ""
    let bVal = b[orderBy] !== null ? b[orderBy] : ""

    // if (typeof aVal === 'string' && typeof bVal === 'string' && ((aVal.includes(',') && bVal.includes(',')) || (!isNaN(aVal) && !isNaN(bVal)))) {

    //     aVal = parseInt(aVal.replaceAll(',', ''))
    //     bVal = parseInt(bVal.replaceAll(',', ''))
    // }
    if (typeof aVal === 'string' && (aVal.includes(',') || !isNaN(aVal))) {
        aVal = parseInt(aVal.replaceAll(',', ''))
    }
    if (typeof bVal === 'string' && (bVal.includes(',') || !isNaN(bVal))) {
        bVal = parseInt(bVal.replaceAll(',', ''))
    }

    if (bVal < aVal) {
        return -1;
    }
    if (bVal > aVal) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'retailer',
        position: 1,
        label: 'Retailer',
    },
    {
        id: 'client_name',
        position: 1,
        label: 'Brand',
    },
    {
        id: 'weekend',
        position: 1,
        label: 'Week-Ending',
    },
    {
        id: 'statusState',
        position: 0,
        label: 'SKYPAD Status',
    },
    {
        id: 'action',
        position: 0,
        label: 'Actions',
    },
    {
        id: 'currency',
        position: 0,
        label: 'Currency',
    },
    {
        id: 'slsUt',
        position: -1,
        label: 'Sold Qty',
    },
    {
        id: 'slsUtChg',
        position: -1,
        label: 'Sold Qty % Chg to LW',
    },
    {
        id: 'slsDlr',
        position: -1,
        label: 'Sales Value',
    },
    {
        id: 'slsDlrChg',
        position: -1,
        label: 'Sls V % Chg to LW',
    },
    {
        id: 'ohUt',
        position: -1,
        label: 'Stock Qty',
    },
    {
        id: 'ohUtChg',
        position: -1,
        label: 'Stk Qty % Chg to LW',
    },
    {
        id: 'ohDlr',
        position: -1,
        label: 'Stock Value',
    },
    {
        id: 'ohDlrChg',
        position: -1,
        label: 'Stk V % Chg to LW',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StickyTableCell>
                    {headCells.map((headCell, index) => {
                        if (index <= 2) {
                            return (
                                <>
                                    <StyledTableCell
                                        sx={{ backgroundColor: 'transparent !important', width: '37%' }}  // '36.6%'
                                        key={headCell.id}
                                        align={headCell.position === 1 ? 'left' : headCell.position === 0 ? 'center' : 'right'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        {headCell.id === 'action' ?
                                            headCell.label
                                            :
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        }
                                    </StyledTableCell>
                                </>
                            )
                        }
                    })}
                </StickyTableCell>
                {headCells.map((headCell, index) => {
                    if (index > 2) {
                        return (
                            <>
                                <StyledTableCell
                                    key={headCell.id}
                                    align={headCell.position === 1 ? 'left' : headCell.position === 0 ? 'center' : 'right'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    {headCell.id === 'action' ?
                                        headCell.label
                                        :
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    }
                                </StyledTableCell>
                            </>
                        )
                    }
                })}
            </TableRow>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function HistoryTable({ fileData, userGroup }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('retailer');
    const { getAccessTokenSilently, user } = useAuth0();
    const { PastFileDownloadAPI } = useContext(ApiContext);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const notSubmitted = <Typography variant='body2' sx={{ padding: '2px', bgcolor: '#FF5B5B', borderRadius: 1, fontWeight: 'bold', color: '#FFFFFF', textAlign: 'center' }} >
        Not Submitted
    </Typography>

    const positiveTooltip = <Tooltip title='More than a 20% change from last week' placement='bottom' arrow>
        <InfoIcon sx={{ color: '#D3D3D3', fontSize: 15 }} />
    </Tooltip>

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const pastDownload = async (row) => {
        PastFileDownloadAPI(row, user.nickname, userGroup)
            .then(
                (response) => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', row.skypadFile);
                    document.body.appendChild(link);
                    link.click();
                },
                (reject) => {
                    console.log(reject)
                })
    };

    useEffect(async () => {
        setPage(0)
    }, [fileData])

    return (
        <>
            <Box sx={{ bgcolor: '#fff', boxShadow: 3, borderRadius: 1 }} >
                <CardHeader
                    sx={{ fontFamily: 'serif !important' }}
                    title={
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h6" gutterBottom component="div" > History Files </Typography>
                            < Tooltip title='Files listed here are for download only. 
                            Please contact Asquad@skyitgroup.com to request changes.' placement='right' arrow>
                                <Chip label="i" size="small"
                                    sx={[
                                        {
                                            fontWeight: 'bold', height: '20px', marginTop: '6px !important', background: '#D9D9D9', color: '#fff',
                                            '&:hover': {
                                                backgroundColor: '#C7C7C7',
                                            },
                                            '& svg': {
                                                color: '#fff !important',
                                            },
                                            '& span': {
                                                padding: '0px 9px',
                                                fontSize: '12px'
                                            }
                                        },
                                    ]} />
                            </Tooltip>
                        </Stack>}
                    style={{ textAlign: 'left', paddingTop: '0px', paddingBottom: '0px', margin: '-1px', fontFamily: 'serif !important' }}
                />

                <Divider />

                <TableContainer>
                    <Table size="small">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        {fileData.length !== 0 ?
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? stableSort(Object.values(fileData), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : stableSort(Object.values(fileData), getComparator(order, orderBy))
                                ).map((item, row) => (
                                    <TableRow key={row} hover={true}>
                                        <StickyTableCell >
                                            <StyledTableCell component="th" scope="row" sx={{ minWidth: 160, borderBottom: 0 }}>
                                                {item.retailer}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="left" sx={{ minWidth: 180, borderBottom: 0 }}>
                                                {item.client_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="left" sx={{ minWidth: 120, borderRight: '2px solid rgba(241, 243, 244, 1) !important', height: '40px', borderBottom: 0 }}>
                                                {item.weekend}
                                            </StyledTableCell>
                                        </StickyTableCell>
                                        {/* <StyledTableCell align="center" sx={{ minWidth: 160 }}> */}
                                        <StyledTableCell align="center" sx={{ minWidth: 160, width: 0 }}>
                                            {item.statusState === "Uploaded" ? <Typography variant='body2' sx={{ padding: '2px', color: '#FFFFFF', bgcolor: '#95D268', borderRadius: 1, fontWeight: 'bold', textAlign: 'center' }} >
                                                Uploaded
                                                <Tooltip style={{ marginLeft: 2, position: 'relative', top: 2 }} title={<>
                                                    User: {item.user}
                                                    <br></br>
                                                    Date: {item.uploaded_time}
                                                </>} placement='bottom' arrow>
                                                    <InfoIcon sx={{ color: '#D3F7BF', fontSize: 15 }} />
                                                </Tooltip>
                                            </Typography> : notSubmitted}

                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ minWidth: 90, maxWidth: 90 }}>
                                            {item.statusState === "Uploaded" ?
                                                <div>
                                                    <Tooltip style={{ marginLeft: 2 }} title={<>
                                                        Download previously
                                                        <br></br>
                                                        uploaded file
                                                    </>} placement='bottom' arrow>
                                                        <SimCardDownloadOutlinedIcon onClick={() => pastDownload(item)} />
                                                    </Tooltip>
                                                </div> : <></>}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ minWidth: 90, maxWidth: 90 }}>
                                            {item.currency}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" sx={{ minWidth: 105 }}>
                                            {item.slsUt}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ minWidth: 175, color: item.slsUtChg > 20 || item.slsUtChg < -20 ? '#FF5272' : 'black' }} align="right">
                                            {item.slsUtChg}%
                                            {item.slsUtChg > 20 ? positiveTooltip : null}
                                            {item.slsUtChg < -20 ? positiveTooltip : null}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" sx={{ minWidth: 105 }}>
                                            {item.slsDlr}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ minWidth: 155, color: item.slsDlrChg > 20 || item.slsDlrChg < -20 ? '#FF5272' : 'black' }} align="right">
                                            {item.slsDlrChg}%
                                            {item.slsDlrChg > 20 ? positiveTooltip : null}
                                            {item.slsDlrChg < -20 ? positiveTooltip : null}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" sx={{ minWidth: 105 }}>
                                            {item.ohUt}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ minWidth: 165, color: item.ohUtChg > 20 || item.ohUtChg < -20 ? '#FF5272' : 'black' }} align="right">
                                            {item.ohUtChg}%
                                            {item.ohUtChg > 20 ? positiveTooltip : null}
                                            {item.ohUtChg < -20 ? positiveTooltip : null}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" sx={{ minWidth: 115 }}>
                                            {item.ohDlr}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ minWidth: 155, paddingTop: 4, paddingBottom: 4, color: item.ohDlrChg > 20 || item.ohDlrChg < -20 ? '#FF5272' : 'black' }} align="right">
                                            {item.ohDlrChg}%
                                            {item.ohDlrChg > 20 ? positiveTooltip : null}
                                            {item.ohDlrChg < -20 ? positiveTooltip : null}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}


                            </TableBody> :
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={14} align="center" style={{ color: 'gray' }}>
                                        No files to display
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        }

                    </Table>
                </TableContainer>
                <Box>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={Object.keys(fileData).length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ maxHeight: '42px', overflow: 'hidden' }}
                    />
                </Box>
            </Box >
        </>
    );
}
