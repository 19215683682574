import { useState, useContext } from 'react'
import FormSection from './formSection';
import { Grid } from '@material-ui/core';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import validateForm from '../../components/validation/validateForm';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth0 } from '@auth0/auth0-react';
import { HeaderContext } from '../../components/dashboard';
import { ApiContext } from '../../contexts/ApiContext';

function TemplatePage({ tabName }) {
    const DownloadBtn = styled(Button)`
    color: #595959;
    background-color: #5569FF; 
    color: #ffffff;
    font-size: 15px;
    width:195px;
    height:36px;
    :hover{
        background:#334BFF;
    }
  `;

    const formLogin = async () => {
        setLoading(true);
        let download = await downloadButton()
        if (download)
            setLoading(false)
    }

    const fileTypeArr = [{
        key: 'store',
        file: 'StoreMaster.xlsx'
    },
    {
        key: 'style',
        file: 'StyleMaster.csv'
    },
    {
        key: 'retail',
        file: 'POS template.xlsx'
    }]

    const [loading, setLoading] = useState(false);
    const { handleSubmit } = validateForm(formLogin);
    const [lRetailer, setLRetailer] = useState(null);
    const [lBrand, setLBrand] = useState(null);
    const [lSesson, setLSession] = useState(null);

    const { user } = useAuth0();
    const { TemplateDownloadAPI } = useContext(ApiContext);
    const { selectedClient, selectedRetailer, originalData, fileData, userGroup } = useContext(HeaderContext);

    const getFileDownloadInfo = (tabName) => {
        return { type: tabName, val: tabName === 'style' && lBrand ? `${lBrand},${lSesson}` : lRetailer, user: user.nickname, client: selectedClient.key !== "All" ? selectedClient.key : "", retailer: selectedRetailer !== "All" ? selectedRetailer : "", userGroup: userGroup }
    }

    const downloadButton = async () => {
        if (tabName) {
            return new Promise(async (resolve) => {
                
                TemplateDownloadAPI(getFileDownloadInfo(tabName))
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    const fileName = lRetailer ? originalData.filter(val => val.retailer === lRetailer)[0].fileName : ""
                    link.href = url;
                    if (tabName === 'retail') {
                        link.setAttribute('download', `${fileName} YYYYMMDD.xlsx`);
                    }
                    else {
                        link.setAttribute('download', fileTypeArr.filter(f => f.key === tabName)[0].file);
                    }
                    //link.setAttribute('download', fileTypeArr.filter(f => f.key === tabName)[0].file);
                    document.body.appendChild(link);
                    link.click();
                    resolve(true);
                })
            })
        }
    };

    const localValidation = () => {
        if (tabName === 'style') {
            return (selectedClient.key !== 'All' && lSesson) || (lBrand && lSesson);
        } else {
            return selectedRetailer !== 'All' || lRetailer;
        }
    }

    return (
        <div className="UploadPage" style={{ padding: '15px 15px' }}>
            <Grid container direction='row' style={{ backgroundColor: '#e4ebf5' }}>
                <Grid item lg={12} style={{ textAlign: 'center' }} >

                    <div style={{ fontSize: '20px', fontWeight: '700' }}>Download a
                        {
                            tabName === 'store' ? ' Store Master'
                                : tabName === 'style' ? ' Style Master'
                                    : tabName === 'retail' ? ' POS Template'
                                        : ''
                        }
                    </div>
                    <form onSubmit={handleSubmit}>
                        <FormSection formName={tabName} values={{ lRetailer, lBrand, lSesson }} setLRetailer={setLRetailer} setLBrand={setLBrand} setLSession={setLSession} />
                        {localValidation() &&
                            <DownloadBtn type="submit">
                                {loading && <CircularProgress sx={{ color: 'white', height: '25px !important', width: '25px !important' }} />}
                                {!loading && 'Download'}
                            </DownloadBtn>
                        }
                    </form>
                </Grid>
            </Grid>
        </div >
    );
}

export default TemplatePage;
