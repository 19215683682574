import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { OverlayProvider, ApiProvider } from './contexts/provider';
import ThemeProvider from './theme';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <OverlayProvider>
        <Auth0Provider
          domain='skypad.auth0.com'
          clientId='CpxSYe5pmRgEU1fGhxBNqcb3BfT1nGBF'
          redirectUri={window.location.origin}
          audience='https://www.skypad-node-api.com'
          //scope="openid profile email"
          scope="openid"
          responseType="token id_token"
          state="skybazaar"
        >
          <ApiProvider>
            <App />
          </ApiProvider>
        </Auth0Provider>
      </OverlayProvider>
    </ThemeProvider>
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();